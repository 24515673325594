
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { fetchAuthSession } from 'aws-amplify/auth';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})


export class AppInterceptor implements HttpInterceptor {

    constructor(
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(req, next));
    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        // if your getAuthToken() function declared as "async getAuthToken() {}"
        const tokens = (await fetchAuthSession()).tokens;
        // const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        const idTokenPayload: any = tokens?.idToken!.payload!
        const organizations  = idTokenPayload["cognito:groups"][0];
        const email = idTokenPayload["email"]?.toString()
        let request = req;
        if (tokens && tokens.idToken) {
            request = req.clone({
                setHeaders: {
                    'Authorization': 'Bearer ' + tokens.idToken.toString(),
                    'Content-Type': 'application/json',
                    // 'IDOrganizacion': organizations!.toString(),
                    // 'IDUsuario': email!,
                    // 'RequestOrigin': 'WEB'
                }
            });
            if(!req.url.includes(environment.apis.userAccess)) {
                request = request.clone({
                    setHeaders: {
                        // 'Authorization': 'Bearer ' + tokens.idToken.toString(),
                        // 'Content-Type': 'application/json',
                        'IDOrganizacion': organizations!.toString(),
                        'IDUsuario': email!,
                        'RequestOrigin': 'WEB'
                    }
                });
            }
        } else {
            request = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });   
        }
    
        return await lastValueFrom(next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    // this.inactivityService.onStopWatching();
                    // this._cognitoService.logout();
                }

                return throwError(err);
            })));
      }

}