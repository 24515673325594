import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _http: HttpClient) { }

  // {
  //   "userPoolID": this.userPoolID,
  //   "userName": user.username,
  //   "name": user.firstName,
  //   "givenName": user.givenName,
  //   "phoneNumber": user.phoneNumber,
  //   "organization": this._sessionService.getOrganization(),
  //   "accessList": user.systemUserAccessList,
  //   "profilesList": ["WEB"], // NOTE: this is the default user profilesList for "activated"
  //   "operation": EUsuarioServiceAcctions.CREATE_USER
  // }

  searchUser(idOrganizacion: string, user: string) {
    const body = {
      "userPoolID": environment.cognito.userPoolID,
      "userName": user,
      "organization":idOrganizacion,
      "operation" : "searchUser"
    }

    return this._http.post<any>(environment.apis.userAccess + 'userAccess', body, {responseType: 'json'});    
  }

  createUser(idOrganizacion: string, email: string, name: string, givenName: string, role: string) {
    
    const body = {
      "userPoolID": environment.cognito.userPoolID,
      "userName": email,
      "name": name,
      "phoneNumber": "",
      "givenName": givenName,
      "organization": idOrganizacion,
      "accessList": [
          role
      ],
      "profilesList": [
          "WEB"
      ],
      "operation": "createUser"
    }

    return this._http.post<any>(environment.apis.userAccess + 'userAccess', body, {responseType: 'json'});
  }

  getUsers(idOrganizacion: string) {
    const body = {
      "userPoolID": environment.cognito.userPoolID,
        "organization" : idOrganizacion,
        "operation":"getUsersForGroup"
    }

    return this._http.post<any>(environment.apis.userAccess + 'userAccess', body, {responseType: 'json'});
  }

  deleteUser(idOrganizacion:string, email: string) {
    const body = {
      "userPoolID": environment.cognito.userPoolID,
      "userName" : email,
      "organization":idOrganizacion,
      "operation":"deleteUser"
    }

    return this._http.post<any>(environment.apis.userAccess + 'userAccess', body, {responseType: 'json'});
  }

  getUserAccess(idOrganizacion: string, email: string) {
    const body = {
      "userName": email,
      "organization": idOrganizacion,
      "operation" : "getAccessForUser"
    }

    return this._http.post<any>(environment.apis.userAccess + 'userAccess', body, {responseType: 'json'});
  }
}
