import { Injectable, signal, WritableSignal } from '@angular/core';

interface AppState {
  email: string,
  organization: string,
  accesos: string[]
}

const emptyState = {
  email: "",
  organization: "",
  accesos: []
}

const accesKeys = {
  ADMIN: 'USER-ADMIN',
  SUPER_ADMIN: 'SUPER-ADMIN'
}

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private appState: WritableSignal<AppState> = signal(emptyState)

  constructor() { }

  setState(email: string, organization: string, accesos: string[]) {
    const state: AppState = { email, organization, accesos }
    this.appState.set(state);
  }

  getState() {
    return this.appState.asReadonly();
  }

  deleteState() {
    this.appState.set(emptyState);
  }

  isAdmin(): boolean {
    const accesos = this.appState().accesos;
    const isAdmin = accesos.includes(accesKeys.ADMIN);
    const isSuperAdmin = accesos.includes(accesKeys.SUPER_ADMIN)

    if(isAdmin || isSuperAdmin) {
      return true;
    } else {
      return false;
    }
  }
}
